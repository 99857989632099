import { ArrowLeftCircleIcon } from "lucide-react";
import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import firebase from "firebase/app";
import "firebase/database";
import DatePicker from "react-datepicker";
import "../screens/link_gen.css";
import "./Givepro.css";
import { LucideLoader } from "lucide-react";

const GivePro = ({ setactive }) => {
  const [email, setemail] = useState("");
  const [Users, setUsers] = useState([]);
  const [uid, setUid] = useState("");
  const [status, setStatus] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchStatus, setSearchStatus] = useState(null);
  const [membershipend, setMembershipEnd] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isPro, setIsPro] = useState(null);
  const [alertShown, setAlertShown] = useState(false);

  useEffect(() => {
    // Fetch all users initially
    const fetchAllUsers = async () => {
      try {
        const userRef = firebase.database().ref("/users/");
        const snapshot = await userRef.once("value");
        if (snapshot.exists()) {

          setUsers(Object.values(snapshot.val()));
        }
      } catch (error) {
        console.error("Error retrieving users:", error);
      }
    };
    fetchAllUsers();
  }, [email]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setemail(value);

    if (value.length > 0) {
      const suggestions = Users.filter((user) =>{
        if(user.email !== undefined){
          return user.email.toLowerCase().includes(value.toLowerCase())
        }
      });

      setFilteredUsers(suggestions);
    } else {
      setFilteredUsers([]);
    }
  };


  async function SearchUser(e) {

    e.preventDefault();

    setLoading(true);

    const fetchedUsers = await getData(); // Fetch users and store them in a local variable

    if (fetchedUsers.length > 0) {
      setUsers(fetchedUsers); // Update state with fetched users
      fetchedUsers[0].membership === 'normal' ? setStatus('') : setStatus('User Already has Pro Account')
      setIsPro(fetchedUsers[0]?.membership?.status?.toLowerCase()  === "pro" || fetchedUsers[0]?.membership?.Status?.toLowerCase() === "pro" ? true: false)
      setMembershipEnd(fetchedUsers[0]?.membership?.date_end || fetchedUsers[0]?.membership?.Date_end)
      setSearchStatus(0); // Assuming only one user is fetched
      setFilteredUsers([]);
    } else {
      setSearchStatus(null);
      setUsers([]); // Clear the users state
      setemail(""); // Reset email input
    }
    setLoading(false)

  }
  

  function handleStatusChange() {
    if (searchStatus !== null) {
      setUsers((prevUsers) => {
        // Clone the user array
        const updatedUsers = [...prevUsers];
  
        // Clone the specific user object
        const userClone = { ...updatedUsers[searchStatus] };
  
        // Ensure `membership` is an object before modifying it
        if (typeof userClone.membership !== "object") {
          userClone.membership = { status: "normal" }; // Default structure if corrupted
          setIsPro(false)
        } else {
          // Clone the membership object to update the status
          userClone.membership = { ...userClone.membership };
        }
  
        // Toggle the status
        if(userClone.membership.Status){
          userClone.membership.Status =
          userClone.membership.Status === "pro" ? "normal" : "pro";
          setIsPro(userClone.membership.Status === "pro" ? true: false);
        }
        else{
          userClone.membership.status =
          userClone.membership.status === "pro" ? "normal" : "pro";
          setIsPro(userClone.membership.status === "pro" ? true: false);
        }

        // Replace the user object in the updated users array
        updatedUsers[searchStatus] = userClone;  
  
        return updatedUsers;
      });
    }
  }
   
  const today = new Date()
  today.setHours(0,0,0,0)
  
  const toLocalISOString = (dateObj) => {
    const offset = dateObj.getTimezoneOffset();
    const adjustedDate = new Date(dateObj.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString() // Only keep the date part
  };

  function handleDateChange(date, type) {
    if (searchStatus !== null) {
      setUsers((prevUsers) => {
        const updatedUsers = [...prevUsers];
        const userClone = { ...updatedUsers[searchStatus] };
  
        // Ensure membership exists
        userClone.membership = { ...userClone.membership };

  
        // Update the appropriate date or reset it if null
        if (type === "start") {
          const selectedDate = new Date(date);
          selectedDate.setHours(0, 0, 0, 0);
          if (selectedDate < today) {
            alert("Start date cannot be earlier than today.");
          }
          else if (
            userClone.membership.date_end &&
            new Date(date) > new Date(userClone.membership.date_end)
          ) {
            alert("Start date cannot be after the end date.");
          } else {
            userClone.membership.date_start = date ? toLocalISOString(date) : null;
          }
    
        } else if (type === "end") {
          if (
            userClone.membership.date_start &&
            new Date(date) < new Date(userClone.membership.date_start)
          ) {
            alert("End date cannot be before the start date.");
          } else {
            userClone.membership.date_end = date ? toLocalISOString(date) : new Date("2099-12-31").toISOString()
          }
        }
  
        updatedUsers[searchStatus] = userClone;
        
        return updatedUsers;
      });
    }
  }
  
  const getData = async () => {
    try {
      const userRef = firebase
        .database()
        .ref("/users/")
        .orderByChild("email")
        .equalTo(email);

      const snapshot = await userRef.once("value");
      if (snapshot.exists()) {
        const userUIDs = Object.keys(snapshot.val());
        setUid(userUIDs[0])
        return Object.values(snapshot.val()); // Return the fetched users
      } else {
        alert("No user found with the specified email.");
        return [];
      }
    } catch (error) {
      console.error("Error retrieving data:", error);

      return [];
    }
  };

  const Save = () => {

    const user = { ...Users[searchStatus] };

    // Ensure the membership object exists and date_end is set
    user.membership = { ...user.membership };
    if (!user.membership.date_end) {
      user.membership.date_end = new Date("2099-12-31").toISOString();
    }

    firebase.database().ref('/users/' + uid)
    .update({membership: user.membership})
    .then(() => {
      setStatus('')
      alert("User has been granted pro")
      })
    .catch((e) => alert(e))

  }

  const Remove_Pro = (check) => {
    if (Users && Users[searchStatus]) {
      // Check if membership property is defined and valid
      if (typeof Users[searchStatus].membership !== "object") {
        Users[searchStatus].membership = { status: "normal", date_start: null, date_end: toLocalISOString(today) }; // Default structure
      } else {
        Users[searchStatus].membership.status = "normal"; // Update status
        Users[searchStatus].membership.date_start = null;
        Users[searchStatus].membership.date_end = toLocalISOString(today)
      }
      setIsPro(false);

      if(check && !alertShown){
        alert("The user's membership has ended as the end date has passed.");
        setMembershipEnd(null)
        setAlertShown(true);
      }
    } else {
      console.log("Users or Users[searchStatus] is undefined.");
    }
  }

  const Save_Reset = () => {

    firebase.database().ref('/users/' + uid)
    .update({membership: "normal"})
    .then(() => {

      Remove_Pro(false)

      setStatus('')
      alert("User has been reverted back to normal")
    })
    .catch((e) => alert(e))

  }

  useEffect(() => {
    if (membershipend !== '') {
      const currentDate = new Date(); // Convert date to Date object
      const membershipEndDate = new Date(membershipend);
      if (currentDate >= membershipEndDate) {
        firebase
          .database()
          .ref(`/users/${uid}`)
          .update({ membership: 'normal' })
          .then(() => {
            Remove_Pro(true)
          })
          .catch((error) => {
            console.log("Error updating membership:", error);
          });
      }
    }
  }, [membershipend, alertShown]);
  
  return (
    <div className="give-pro-container backdrop_image">
      <div className="header-container ">
        <ArrowLeftCircleIcon
          className="back-button"
          onClick={() => setactive(null)}
        />
        <h1 className="page-title">Manager</h1>
      </div>

      <div className="search-container">
        <form onSubmit={SearchUser} className="search-form">
          <label htmlFor="password" className="search-label">
            Search User:
          </label>
          <div className="field">
            <input
              type="email"
              id="user"
              placeholder="Enter email address"
              style={{width: 200}}
              value={email}
              onChange={(e) => handleInputChange(e)}
            />
            <button disabled={loading} type="submit" style={{
                    width: '20%',
                    height: "30px",
                    backgroundColor: loading ? "gray" : "#4b92f0",
                    color: 'white',
                    border: 'none',
                    padding: '5px',
                    borderRadius: '5px',
                    cursor: loading ? "not-allowed": 'pointer',
                  }}>
              Search
            </button>
          </div>
        </form>
        {filteredUsers.length > 0 && (
  <div className="h-auto max-h-60 overflow-y-auto border border-gray-300 rounded-md mt-1 bg-white shadow-md">
    <ul className="list-none">
      {filteredUsers.map((user, index) => (
        <li
          key={index}
          className="suggestion-item px-3 py-2 hover:bg-gray-100 cursor-pointer"
          onClick={() => {
            setemail(user.email);
            setFilteredUsers([]);
          }}
        >
          {user.email}
        </li>
      ))}
    </ul>
  </div>
)}

        {loading ? (
          <div className="flex flex-1 justify-center items-center p-4">
          <LucideLoader className="animate-spin" size={30}/>
          </div>
        ) : (
          searchStatus !== null && Users.length > 0 && (
            <div className="user-info-container">
              <div className="user-info">
                <div className="user-row">
                  <label className="user-label">User:</label>
                  <label>{Users[searchStatus].name}</label>
                </div>
                <div className="user-row">
                  <label className="user-label">Status:</label>
                  <label>{Users[searchStatus].membership !== "normal" ? Users[searchStatus].membership.status || Users[searchStatus].membership.Status: "Normal"}</label>
                </div>
                {/* {status ? (
                  <label className="user-row">User Already has Pro Account</label>
                ): (
                  
                )} */}

                <div className="user-row">
                  <label className="user-label">Toggle:</label>
                  <Switch
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={handleStatusChange}
                    checked={
                      isPro
                    }
                    className="scale-90"
                  />
                </div>
                {(Users[searchStatus].membership.Status === "pro" || Users[searchStatus].membership.Status === "Pro") || (Users[searchStatus].membership.status === "pro" || Users[searchStatus].membership.status === "Pro") ? null : (
                  <button
                  style={{
                    width: '20%',
                    marginTop: '10%',
                    backgroundColor: (Users[searchStatus].membership.Status === "normal" || Users[searchStatus].membership.Status === "Normal") || (Users[searchStatus].membership.status === "normal" || Users[searchStatus].membership.status === "Normal") ? "#4b92f0" : 'gray',
                    color: 'white',
                    border: 'none',
                    padding: '10px',
                    borderRadius: '5px',
                    cursor: (Users[searchStatus].membership.Status === "normal" || Users[searchStatus].membership.Status === "Normal") || (Users[searchStatus].membership.status === "normal" || Users[searchStatus].membership.status === "Normal") ? 'pointer' : 'not-allowed',
                  }}
                  //disabled={Users[searchStatus].membership.status !== "normal" || Users[searchStatus].membership.status !== "Normal"}
                  onClick={Save_Reset}
                >
                  Save
                </button>
                )}
                
                
              </div>
                {/* {status ? null : (
                   
                )} */}
                
                <div
                className={`calendar-container ${
                  (Users[searchStatus].membership.status === "pro" || Users[searchStatus].membership.status === "Pro") || (Users[searchStatus].membership.Status === "pro" || Users[searchStatus].membership.Status === "Pro") ? "visible" : "hidden"
                }`}
              >
                <h1 className="calendar-header">
                  Please Select Duration of Membership
                </h1>
                <div className="date-picker-container">
                  <label className="date-picker-label">
                    <span>Start Date:</span>
                    <DatePicker
                      allowSameDay
                      selected={Users[searchStatus]?.membership?.date_start || Users[searchStatus]?.membership?.Date_start ? new Date(Users[searchStatus].membership.date_start || Users[searchStatus].membership.Date_start) : null}
                      onChange={(date) => handleDateChange(date, "start")}
                      dateFormat="yyyy/MM/dd"
                      className="date-picker-input"
                    />
  
                  </label>
                  <label className="date-picker-label">
                    <span>End Date:</span>
                    <DatePicker
                      selected={Users[searchStatus]?.membership?.date_end || Users[searchStatus]?.membership?.Date_end ? new Date(Users[searchStatus].membership.date_end || Users[searchStatus].membership.Date_end) : new Date("2099-12-31")}
                      onChange={(date) => handleDateChange(date, "end")}
                      
                      dateFormat="yyyy/MM/dd"
                      className="date-picker-input"
                    />
                  </label>
                </div>
                <button
                  style={{
                    width: '20%',
                    marginTop: '10%',
                    backgroundColor: (Users[searchStatus]?.membership?.date_start) || (Users[searchStatus]?.membership?.Date_start) ? "#4b92f0" : 'gray',
                    color: 'white',
                    border: 'none',
                    padding: '10px',
                    borderRadius: '5px',
                    cursor: (Users[searchStatus]?.membership?.date_start) || (Users[searchStatus]?.membership?.Date_start) ? 'pointer' : 'not-allowed',
                  }}
                  disabled={!(
                    (Users[searchStatus]?.membership?.date_start) ||
                    (Users[searchStatus]?.membership?.Date_start)
                  )}
                  
                  onClick={Save}
                >
                  Save
                </button>
  
              </div>
              
  
                       
          </div>
          )
        )}
        
      </div>
    </div>
  );
};

export default GivePro;
